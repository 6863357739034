import React, {useState, useRef} from "react"
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TECollapse,
} from "tw-elements-react";

import { useRecoilState, useRecoilValue } from 'recoil';
import { creatorlinks } from "../../../state/atoms/Creator";

import { addCreatorLinks } from '../../../state/actions/creator';

import imageCompression from 'browser-image-compression';



const NormalModal = ({ showNormalModal, setShowNormalModal, setShowModal, showModal }) => {

    const [linktitle, setLinktitle] = useState('')
    const [url, setUrl] = useState('')
    const [linkType, setLinkType] = useState('normal')
    const [price, setPrice] = useState(0)

    const [loading, setLoading] = useState(false)
    const [loadingPrice, setLoadingPrice] = useState(false)
    const [submittable, setSubmittable] = useState('')


    const [editLogo, setEditLogo] = useState(null);
    const [prologo, setPrologo] = useState(null);
	const [prologoUrl, setPrologoUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);

    
    // const [showNormalModal, setShowNormalModal] = useState(false)

    // const savePrice = () => {
    //     setLoadingPrice(true);
    //      // Set a 2-second delay before hiding the paywall modal
    //     setTimeout(() => {
    //         setShowNormalModal(false);
    //         setLoadingPrice(false);
    //     }, 1500);
    // }

    const hiddenFileInput = useRef(null);

	const handleonLogoClick = event => {
		hiddenFileInput.current.click();
	};

    const handleDeleteLogo = () => {
        setPrologo(null);
        setEditLogo(null);
        setPrologoUrl('');
    }

    const [errors, setErrors] = useState();
    const [isValid, setIsValid] = useState(false);

    const handleChange = (e) => {
        setUrl(e.target.value)

        // Validate URL only if value is not empty
        if (url.trim() !== '') {
            validateURL(url);
        } else {
            setErrors(null);
        }
    };

    const validateURL = (url) => {
        // Regular expression for validating URLs
        const urlRegex = /^(ftp|http|https):\/\/[^ "]*$/;
        const isHereValid = urlRegex.test(url)
        setIsValid(urlRegex.test(url));
        setErrors(isHereValid ? null : "This link is invalid. All fields should begin with 'https://'");

    };

    const onChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          const options = {
            maxSizeMB: 1, // Max size in MB
            maxWidthOrHeight: 1920, // Max width or height
            useWebWorker: true, // Use web workers for better performance
            onProgress: (progress) => {
              setProgress(Math.round(progress));
            },
          };
    
          try {
            const compressedBlob = await imageCompression(file, options);
    
            // Convert the compressed Blob back to a File object
            const compressedFile = new File(
              [compressedBlob],
              file.name,
              { type: compressedBlob.type }
            );
    
            setPrologo(compressedFile);
            setPrologoUrl(URL.createObjectURL(compressedFile));
            console.log(compressedFile);
            setProgress(0); // Reset progress
          } catch (error) {
            alert('Error compressing the image; please refresh the page and try again!');
            setProgress(0); // Reset progress on error
          }
        }
      };


      const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        // console.log(selectedOption.value);
        let formData = new FormData();
        if (prologo != null) {
          formData.append('thumb', prologo);
        } else if (editLogo == null) {
          formData.append('thumb', '');
        }
        formData.append('title', linktitle);
        // formData.append('creator_username', username);
        formData.append('url', url);
        formData.append('type', linkType);
       
    
        addCreatorLinks(formData, setShowNormalModal, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl);
      };



    return(
        <div className="">
          <TEModal show={showNormalModal} setShow={setShowNormalModal} scrollable className='!bg-white [&_*:not(button):not(input):not(textarea):not(select)]:!bg-white'>
            <TEModalDialog className='!p-3 !m-auto'>
                <TEModalContent>
                    {loadingPrice ? 
                        <div className="p-12 h-[300px]">
                            <div
                                className="flex m-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div> 
                        </div>
                        :
                        <>
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-medium font-bold leading-normal text-neutral-800">
                                Simple Link
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setShowNormalModal(false)}
                            aria-label="Close"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>   
                        <form className="" action="#" onSubmit={e => onSubmit(e)}>
                            <div className="upload-logo">
                                <div className="edit-pic">
                                <div className='flex w-full flex-col'>
                                    <p className='mt-2 mb-2 !font-bold' style={{fontSize: '80%'}}>Select Poster</p>
                                    <img src={prologoUrl ? prologoUrl : editLogo} className="linkthumb" />
                                </div>
                                </div>

                                <div onClick={handleonLogoClick} className="selectbtn-img" style={{cursor: 'pointer'}}>
                                <p>Select</p>
                                </div>
                                <div onClick={handleDeleteLogo} className="deletebtn-img" style={{cursor: 'pointer'}}>
                                <p>Delete</p>
                                </div>
                            
                                <input
                                    className='form-control'
                                    ref={hiddenFileInput}
                                    style={{display:'none'}}
                                    id="upload-profile"
                                    type='file'
                                    accept='image/png, image/jpeg, image/gif'
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                                <div>
                                    <label htmlFor="email" className="!font-bold">
                                    Link Title
                                    </label>
                                    
                                    <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        value={linktitle}
                                        onChange={e => setLinktitle(e.target.value)}
                                        className=""
                                    />
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <label htmlFor="email" className="!font-bold">
                                    Enter URL
                                    </label>
                                    
                                    <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        value={url}
                                        onChange={e => handleChange(e)}
                                        className=""
                                    />
                                    </div>
                                    {errors && <p className='uname-val'>{errors}</p>}
                                </div>

                                <div className='submit-comp'>
                                {loading ? 
                                    <div
                                        className="flex ml-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                        <span
                                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                        >Loading...</span>
                                    </div> :
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="complete-button"
                                    >
                                    Save
                                    </button>
                                }
                                    {/* <button
                                        type="submit"
                                        disabled={loadingHeader || !submittable}
                                        className="complete-button"
                                    >
                                    Continue
                                    </button> */}
                                </div>
                            </form> 
                            

                            {/* <div className='submit-comp !w-full !ml-0 text-center'> 
                                <button
                                    onClick={savePrice}
                                    className="complete-button !justify-center !ml-0"
                                >
                                    Save
                                </button>
                            
                            </div> */}

                        </TEModalBody>
                        <TEModalFooter>
                            <TERipple rippleColor="light">
                            {/* <button
                                type="button"
                                className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button> */}
                            </TERipple>
                            
                        </TEModalFooter>
                        </> 
                    }
                    
                </TEModalContent>
            </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default NormalModal;