import React, { useState } from 'react'

import check from "../../images/check.png"

import emailframe from "../../images/emailframe.png"
import questionmark from "../../images/questionmark.png"
import mark from "../../images/mark.png"
import cancel from "../../images/cancel.png"

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ReactGA from "react-ga4";
import Faqs from './faqs.tsx';
import Footer from './footerlp.tsx';


const Prizing = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const PlanCard = ({ title, price, features, description, buttonText, bgColor, textColor, plan }) => (
    <div className='max-w-[392px] w-[90%] lg:w-[60%] mb-5'>
      <div className={`py-7 rounded-t-[20px]`} style={{ backgroundColor: bgColor }}>
        <h3 className='text-white px-4 text-6xl font-bold'>{title}</h3>
      </div>
      <div className='w-[100%] bg-white p-4' >
        <div className='flex flex-col mb-4 justify-between font-thin text-[#6B7280] gap-10" 
'>
          {
            description.map((desc, index) => (
              <p className='p-2'>{desc}</p>
            ))
          }
        </div>
        <hr
          className="mt-12 mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50  w-[90%]"
        />
        <div>
          {
            features.map((feat, index) => (
              <div className='flex gap-3 font-thin text-[#6B7280] '>
                <img src={check} alt="" className='w-[20px]' />
                <p className=''>{feat}</p>
              </div>
            ))
          }


        </div>
        <hr
          className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 mb-[40px] lg:mb-[250px] w-[90%]"
        />
        <div className='flex flex-col gap-4 '>
          <h1 className='lg:text-6xl text-4xl font-semibold'>NGN <span>{price}</span></h1>
          <div className='font-thin text-[#6B7280] py-2'>
            <p>{plan}</p>
          </div>
          <div className='border-slate-100 py-5 px-5 rounded-full border-[1px] text-center mb-5'
            style={{ backgroundColor: bgColor, color: textColor }}
          >
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='pt-20 bg-[#E8E8E8] flex flex-col items-center justify-center '>
      <div className='mt-10 pt-10 mx-auto ' >
        <div className='flex gap-6 items-center flex-col'>
          <h1 className='font-black  text-7xl'>Pricing</h1>
          <p>Save up to 30% </p>

          <div className={` bg-white rounded-full h-[50px] flex gap-2 px-2 py-5  items-center justify-center `}>
            <div onClick={() => handleTabClick('tab1')} className={` ${activeTab === 'tab1' ? "text-white bg-[#00181B]" : "text-black"}  w-[50%] h-[100%] rounded-full flex justify-center items-center px-4 py-5 `}>
              <p>Monthly</p>
            </div>
            <div onClick={() => handleTabClick('tab2')} className={` ${activeTab === 'tab2' ? "text-white bg-[#00181B]" : "text-black"}  w-[50%] h-[100%] rounded-full flex justify-center items-center px-4 py-5 `}>
              <p>Annually</p>
            </div>
          </div>

        </div>
      </div>

      <div className='flex gap-5 items-start justify-center w-full py-10 mt-10 px-4 flex-wrap md:flex-nowrap con' >

        <PlanCard
          title="Free"
          price="0"
          features={["Unlimited links"]}
          description={[
            "For your personal Prymshare",
            "Everything you do, in one single link",
            "Key features"
          ]}
          plan="Free, Forever"
          buttonText="Join us for free"
          bgColor="#1B0015"
          textColor="#FFFFFF"
        />

        <PlanCard
          title="Starter"
          price="4999"
          features={["Unlimited links"]}
          description={[
            "For your personal Prymshare",
            "Everything you do, in one single link",
            "Key features"
          ]}
          plan="NGN/month, billed monthly"
          buttonText="Get Starter"
          bgColor="#121B00"
          textColor="#FFFFFF"
        />



        <PlanCard
          title="Free"
          price="11999"
          features={["Unlimited links"]}
          description={[
            "For your personal Prymshare",
            "Everything you do, in one single link",
            "Key features"
          ]}
          plan="NGN/month, billed monthly"
          buttonText="Get Pro"
          bgColor="#00181B"
          textColor="#FFFFFF"
        />
      </div>

      {/* 
      All features */}
      <div className='pt-20 lg:px-[100px] px-[10px]  max-w-[1440px] w-full' >
        <h1 className='text-6xl font-semibold lg:text-start text-center'>All Features</h1>
        <div className='mt-10 flex flex-wrap  items-start justify-center lg:justify-end gap-[40px]'>

          <div className=' max-w-[256px]'>
            <h1 className='text-3xl font-bold'>Free</h1>
            <div className='flex gap-4'>
              <span className='text-3xl font-bold'>NGN 0</span>
              <span className='font-thin pt-10'>Forever</span>
            </div>
            <div className='mt-10 bg-white   rounded-full  max-w-[240px]  px-20 py-3 lg;text-center text-start'>
              Join for free
            </div>
          </div>

          <div className=' max-w-[256px]'>
            <h1 className='text-3xl font-bold'>Starter</h1>
            <div className='flex gap-4'>
              <span className='text-3xl font-bold'>NGN 4999</span>
              <span className='font-thin pt-10'>NGN/month</span>
            </div>
            <div className='mt-10 bg-black text-white rounded-full  max-w-[240px]  px-20 py-3 text-center'>
              Get Starter
            </div>
          </div>

          <div className=' max-w-[256px]'>
            <h1 className='text-3xl font-bold'>Pro</h1>
            <div className='flex gap-4'>
              <span className='text-3xl font-bold'>NGN 11999</span>
              <span className='font-thin pt-10'>NGN/month</span>
            </div>
            <div className='mt-10 text-white bg-black rounded-full max-w-[240px] px-10 py-3 text-center'>
              Get Pro
            </div>
          </div>


        </div>
        {/* Table */}

        <div className='max-w-[1240px] w-full bg-white mt-20 px-5 py-10 rounded-[30px]'>
          <div className='font-semibold '>Links and buttons</div>
          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Email Collection</span>
              <img src={questionmark} className=" h-[24px] max-w-[24px]" alt="" />

            </div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>

          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Phone Number Collection</span>
              <img src={questionmark} className=" h-[24px] max-w-[24px]" alt="" />

            </div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>

          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Embed Latest YouTube Video</span>
              <img src={questionmark} className=" h-[24px] max-w-[24px]" alt="" />

            </div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div><img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>

          </div>



          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Mobile App Link</span>
              <img src={questionmark} className=" h-[24px] max-w-[24px]" alt="" />
            </div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Animation</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Spotlight</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Payment lock</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Scheduling</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={cancel} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Community Link</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>QR Code</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>QR Code</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Social Icons</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>


          <hr
            className=" mb-4 h-0.5 border-t-0 bg-neutral-300 opacity-100 dark:opacity-50 mt-4 w-full"
          />
          {/* individual */}
          <div className='w-full flex justify-between items-center'>
            <div className='max-w-[424px] flex items-center justify-between w-full'>
              <span>Unlimited Links</span>
              <img src={questionmark} className="max-h-[24px] h-[24px] w-[24px]" alt="" />
            </div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
            <div>   <img src={mark} className=" h-[24px] max-w-[24px]" alt="" /></div>
          </div>
        </div>
      </div>


      <div className="p-4 py-16 lg:py-24 mt-12 lg:mt-16 bg-[#F7F9F4] w-full">
        <div className='flex flex-col justify-between items-center max-w-6xl m-auto'>
          <div className='flex flex-col items-start'>
            <div className='flex flex-col mb-9'>
              <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-start mb-4'>LOST  <br className='lg:hidden' />ON SOMETHING?</h1></AnimationOnScroll>
            </div>
          </div>

          <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='flex w-full w-[100%] flex-col max-w-2xl'>
            <Faqs />
          </AnimationOnScroll>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Prizing