import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { authenticated } from "./Authenticated"
import { authenticated, access, refresh, userid, firstname, lastname, email } from "../../state/atoms/Authenticated";
import { loggedcreator } from "../../state/atoms/Creator";
// import Home from '../containers/Home/home.tsx'
import {
  Link
} from "react-router-dom";
import { redirect, useNavigate } from "react-router-dom";
import Select from 'react-select';

import { signIn, handleGoogleLogin } from '../../state/actions/auth';

import { CreateCreatorProfile } from '../../state/actions/creator';

import Logo from '../../images/logo-main.png';


function Complete() {
	let navigate = useNavigate();

	const [authState, setAuthState] = useRecoilState(authenticated);
	const [accessState, setAccessState] = useRecoilState(access);
	const [refreshState, setRefreshState] = useRecoilState(refresh);
    

	const isAuth = useRecoilValue(authenticated);
    const isCreatorAlready = useRecoilValue(loggedcreator);

    const [firstName, setFirstName] = useRecoilState(firstname);
    const [lastName, setLastName] = useRecoilState(lastname);
    // const firstName = useRecoilValue(firstname);
    // const lastName = useRecoilValue(lastname);
	// const [formData, setFormData] = useState({
 //        email: '',
 //        password: '' 
 //  });
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { value: 'Influencer', label: 'Influencer' },
        { value: 'YouTuber', label: 'YouTuber' },
        { value: 'Podcaster', label: 'Podcaster' },
        { value: 'Content Creator', label: 'Content Creator' },
        { value: 'Media Organisation', label: 'Media Organisation' },
        { value: 'Writer', label: 'Writer' },
        { value: 'Designer', label: 'Designer' },
        { value: 'Event Host', label: 'Event Host' },
        { value: 'Marketer', label: 'Marketer' },
        { value: 'Product Manager', label: 'Product Manager' },
        { value: 'Software Engineer', label: 'Software Engineer' },
        { value: 'Personal Brand', label: 'Personal Brand' },
        { value: 'Public Figure', label: 'Public Figure' },
        { value: 'Researcher', label: 'Researcher' },
        { value: 'Music Creator', label: 'Music Creator' },
        { value: 'Vlogger', label: 'Vlogger' },
        { value: 'Comedian', label: 'Comedian' },
        { value: 'Educator', label: 'Educator' },
        { value: 'NGO', label: 'NGO' },
        { value: 'Photographer', label: 'Photographer' },
        { value: 'Blogger', label: 'Blogger' },
        { value: 'Social Media Manager', label: 'Social Media Manager' },
        { value: 'Author', label: 'Author' },
        { value: 'Techie', label: 'Techie' },
        { value: 'Digital Artist', label: 'Digital Artist' },
    ];

    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [displayName, setDisplayName] = useState('')

    const [userNameAvailable, setUserNameAvailable] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const [loading, setLoading] = useState(false)

    const [submittable, setSubmittable] = useState(false);

    const [bio, setBio] = useState('')

     // const [loading, setLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false)


  useEffect(() => {
  	if (isCreatorAlready && isCreatorAlready !='loading') {
			return navigate("/me/appearance/");
  	}
  }, [isCreatorAlready]);

  useEffect(() => {
    setFirstName(firstName);
    setLastName(lastName);
}, [firstName, lastName]);


  useEffect(() => {
    if (username != '' && bio != '' && displayName != ''  && selectedOption != null && userNameAvailable != null) {
        setSubmittable(true);
    } else {
        setSubmittable(false);
    }
}, [username, bio, displayName, selectedOption]);




  const onUsernameChange = (e) => {
    setUsername(e.target.value);
    const regex = /[^\w]/;
    if (regex.test(e.target.value)) {
        setErrorMessage('Username should not contain punctuation or space characters.');
        setUserNameAvailable(null);
    } else {
        setUserNameAvailable('loading');
        setErrorMessage(null);
        axios
        .get(`${process.env.REACT_APP_API_URL}/api/profile/check/${e.target.value}`)
        .then((res) => {
        //   console.log(res);
        setUserNameAvailable(res.data);
        })
        .catch((err) => {
        console.log("Error", err);
        });
    }

    
  }

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    // console.log(selectedOption.value);
    let formData = new FormData();
    formData.append('display_name', displayName);
    formData.append('creator_username', username);
    formData.append('creator_desc', bio);
    formData.append('category', selectedOption.value);


    CreateCreatorProfile(formData, navigate);
  };

  return (
    <main className='main-complete'>
        <div className='complete-nav'>
            <img src={Logo} />
        </div>
        <div className="complete-profile">
            <div className='step'>
                <h1>Hello {firstName} {lastName}, Create your Page</h1>
                <p>You are one step closer to having your Prime page!</p>
            </div>
            
            <form className="" action="#" onSubmit={e => onSubmit(e)}>
                <div>
                    <label htmlFor="email" className="">
                    Display Name
                    </label>
                    <p>This is the name to be displayed on your page.</p>
                    <div className="mt-2">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        required
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                        className=""
                    />
                    </div>
                </div>

                <div className="username-input">
                    <label>Username</label>
                    <div className="username-child">
                        <p className="pre-input">
                        prymshare.co/
                        </p>
                        <p className='w-full'><input className="input-onboard" name='username' value={username} placeholder="your username" onChange={onUsernameChange}
                        /></p>
                    </div>
                    {errorMessage != null && <p className='uname-val'>{errorMessage}</p>}

                    {userNameAvailable && username != '' ? <p style={{color: 'green'}}>Username is available</p> : <></>}
                    {!userNameAvailable && username != '' ?  <p style={{color: 'red'}}>Username is not available</p> : <></>}
                    {userNameAvailable === 'loading' && username != '' ?  <p style={{color: 'green'}}>Checking username availability...</p> : <></>}

                </div>

                <div className='bio-input'>
                    <label htmlFor="email" className="">
                    Bio
                    </label>
                    <p>Write a brief description of yourself, and what you do, to be displayed on your page.</p>
                    <div className="mt-2">
                    <textarea
                        id="bio"
                        name="bio"
                        type="bio"
                        required
                        value={bio}
                        maxLength={250}
                        onChange={e => setBio(e.target.value)}
                        className=""
                    />
                    </div>
                    <div className="char-count text-xs text-neutral-500">
                        {bio.length}/250 characters {/* Character count display */}
                    </div>
                </div>

                <div className='select'>
                    <label>What do you do?</label>
                    <p>Pick a title that best describes you!</p>
                    <Select
                        defaultValue={selectedOption}
                        onChange={setSelectedOption}
                        options={options}
                    />
                </div>

                
                
                <div className='divider-profile'>
                    <div></div><div className='two'></div>
                </div>
                

                <div className={loading || !submittable ? 'submit-com-dis' : 'submit-comp'}>
                    <button
                        type="submit"
                        disabled={loading}
                        className="complete-button"
                    >
                    Continue
                    </button>
                </div>
            </form>
        </div>
    </main>
  	
  );
}

export default Complete;
