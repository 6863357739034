import { useEffect, useState, Fragment, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './giftblock.css';

// import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Delete from '../../../images/delete.png';
import Edit from '../../../images/edit.png';
import Plus from '../../../images/plus.png';
import Minus from '../../../images/minus.png';
import Heart from '../../../images/Heart.png';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';



export const PayAsk = ({ creatorId, giftblock, open, setOpen }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [submittable, setSubmitttable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const [amount, setAmount] = useState(1000);
    const [price, setPrice] = useState(1000);

    useEffect(() => {
      if(giftblock != null) {
          setAmount(giftblock.amount);
          setPrice(giftblock.amount)
      }
     
  }, [giftblock]);

  
    const type = 'gift';
  
    const initializePayment = async (e) => {
      e.preventDefault();
      setLoading(true);
  
      const data = {
        creator_id: creatorId,
        email,
        name, 
        comment,
        amount,
        type,
        paywall_id: null,
      };
  
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/initialize_payment/`, data);
        if (response.data.status === 'success') {
          window.location.href = response.data.authorization_url;
        } else {
          alert('Payment initialization failed');
        }
      } catch (error) {
        console.error('Error initializing payment:', error);
        alert('Payment initialization failed');
      } finally {
        setLoading(false);
      }
    };
  

    const Remove = () => {
      if(quantity > 1) {
          setQuantity(quantity - 1);
          setAmount((quantity-1) * price);
      }

    }

    const Add = () => {
        setQuantity(quantity + 1);
        setAmount((quantity + 1) * price);
        // console.log(quantity)
    }

    // const location = useLocation();
    // const query = new URLSearchParams(location.search);
    // const reference = query.get('reference');
  
    // useEffect(() => {
    //   const verifyPayment = async () => {
    //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/verify-payment/?reference=${reference}`);
        
    //     // Handle the response data, update UI accordingly
    //   };
  
    //   if (reference) {
    //     verifyPayment();
    //   }
    // }, [reference]);
  
    useEffect(() => {
       if (email != '') {
        setSubmitttable(true);
        console.log(submittable)
       } else {
        setSubmitttable(false)
       }
    }, [email]);
  
    return (
      <Modal style={{borderRadius: '20px'}} open={open} onClose={() => setOpen(false)} center> 
      <div className="gift-block">
            <h2 className='font-bold text-base'>Show Thanks</h2>
            <h3 className='!font-semibold !text-xs'>Buy me some Prym stars</h3>
            <div style={{width: '100%'}} className="giftblock-set">
                <div className='price-set'>
                  <div className='counter'>
                      <img src={Minus} className='w-[28px] cursor-pointer' onClick={Remove} />
                      <p>{quantity}</p>
                      <img src={Plus} className='w-[28px] cursor-pointer' onClick={Add}/>
                  </div>
                  <span style={{fontSize: '100%'}}>₦{price} each</span>
                </div>
                
            </div>

            {/* <button onClick={() => setOpen(true)}>Send ₦{price} for Prym stars</button> */}

            
        </div>   
        <div className='delete-section paywall'>
          {/* <h2 style={{marginBottom: '12px'}}>What's your email?</h2> */}
          <form className='m-0 p-0 flex flex-col items-center w-full' onSubmit={(e) => initializePayment(e)}>
            <input className='input-onboard mb-2 !text-sm' type="email" required placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            <input className='input-onboard mb-2 !text-sm' type="text" required placeholder="Your name" value={name} onChange={(e) => setName(e.target.value)} />
            <textarea
                id="comment"
                name="comment"
                required
                value={comment}
                placeholder="A comment"
                onChange={e => setComment(e.target.value)}
                className="input-onboard rounded-[10px] !p-[10px] !text-sm"
            />

            {loading ? 
              <div
                  className="inline-block h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                  >Loading...</span>
                </div> :
              <button type='submit' className='delete-btn !bg-[#121B00]' style={{marginTop: '4px'}}>Say thanks ₦{amount}</button>
              }
            </form>
          
          {/* <button onClick={() => setOpen(false)}>Cancel</button> */}
        </div>
      </Modal>
    )
  }

  

const GiftBlock = ({ creatorId, creatorName, giftblock }) => {
    
    const [item, setItem] = useState('coffee');
    const { id } = useParams();
    
    const [open, setOpen] = useState(false);
    

    
    

    return (
      <div className='p-[1.6px] rounded-[12px] bg-gradient-to-r from-[#F84F94] to-[#B3EA44] mb-3 link-section'>
          <div onClick={() => setOpen(true)} className="links cursor-pointer !justify-center !w-auto" style={{width: 'auto'}}>
            <div className='link-desc' style={{width: 'auto'}}>
                <h3 className=''>Support {creatorName}</h3>
            </div>           
            <img src={Heart} className="link-arrow !ml-3 !w-[18px]" />
          </div>
          <PayAsk creatorId={creatorId} giftblock={giftblock} open={open} setOpen={setOpen} />
      </div>
        
    );
}

export default GiftBlock;
